import { ref, reactive, toRefs, computed} from 'vue'
import Konva, {Stage, Layer, Group, Text, Line} from 'konva';
import store from '@/store/';
import {usekeyBoard,useSetDragAlign} from '@/use/diy-use.js';
import {createRuler} from '@/api/'
import {drawCanvas} from './index.js'; 


export function drawRecting(width,height,stage) {

    // width = localStorage.getItem('canvasWidth')*1;
    // height = localStorage.getItem('canvasHeight')*1;
    
    console.log("drawRecting..........................");
    const selectCategory = computed(() => store.state.selectCategory);
    // const lists = computed(() => store.state.lists);
    const layer = new Layer();
    const guidWidth =  computed(() => store.state.guidWidth);

    let radio = width/selectCategory.value.width;

    let isPaint = false; //鼠标是否按下
    let startPos;
    let box = null; //临时矩形展位
    let line = null; //临时线段
    let group = null;
    let xSize = null;
    let ySize = null;
    let areaText = null;

    let cursor =null; //鼠标光标矩形，用于显示当前选中的网格
    let axisX = null; //鼠标坐标轴线
    let axisX2 = null; //鼠标坐标轴线(结束轴线)
    let axisXText = null;
    let axisY = null; //鼠标坐标轴线
    let axisY2 = null; //鼠标坐标轴线(结束轴线)
    let axisYText = null;

    let drawType = computed(() => store.state.drawType); //图形类型，矩形或者线段

    let isEnd = false;

    //鼠标按下
    stage.on('mousedown touchstart', function(e) {
      radio = width/selectCategory.value.width;
      let pos = stage.getPointerPosition();
      startPos = pos;
      if(e.target!==stage&&!e.target.hasName('cursor')&&(e.target.attrs&&e.target.attrs.name!=='axis')){
        return false;
      }
      isPaint = true;
      //鼠标按下的时候，隐藏光标矩形
      cursor && cursor.visible(false);
      xRectAxis1&&xRectAxis1.visible(false);
      xRectSide1&&xRectSide1.visible(false);
      xRectText1&&xRectText1.visible(false);
      xRectAxis2&&xRectAxis2.visible(false);
      xRectSide2&&xRectSide2.visible(false);
      xRectText2&&xRectText2.visible(false);
      yRectAxis1&&yRectAxis1.visible(false);
      yRectSide1&&yRectSide1.visible(false);
      yRectText1&&yRectText1.visible(false);
      yRectAxis2&&yRectAxis2.visible(false);
      yRectSide2&&yRectSide2.visible(false);
      yRectText2&&yRectText2.visible(false);
      layer.batchDraw();

      // for(let i=0; i<lists.value.length; i++){
      //   let r = lists.value[i];
      //   let rect = {leftTop:{x:r.left*radio,y:r.top*radio},width: r.width*radio, height: r.height*radio}
      //   //判断起点是否交叉
      //   if(isPointInClosedRegion({x:e.evt.layerX,y:e.evt.layerY},rect)){
      //     isPaint = false;
      //     return false;
      //   }else{
      //     isPaint = true;
      //   }
      // }
      //起点对齐网格线
      const {newX, newY} = getMouseIndex(startPos,radio);
      startPos.x = newX;
      startPos.y = newY;

      // getRectBearby(startPos,radio);
    });

    stage.on('mouseup touchend', function(e) {
        //抬起鼠标删除辅助线
        const pos = stage.getPointerPosition();
        isPaint = false;
        //终点对齐网格线
        const {newX, newY} = getMouseIndex(pos,radio);
        pos.x = newX+guidWidth.value*radio;
        pos.y = newY+guidWidth.value*radio;
        if(!!box&&drawType.value==='rect'){
          //设置组内元素属性
          // setTempRect(pos);
          let groupWidth = pos.x-startPos.x;
          let groupHeight = pos.y-startPos.y;
          if(Math.abs(groupWidth/radio)<1900 || Math.abs(groupHeight/radio)<1900){
            console.log("展位最小宽度不能小于2米");
            group.destroy();
            layer.batchDraw();
          }else{
            try {
              let sg = e.target.findAncestors('Group')[0]
              // console.log(sg);
              if(typeof sg === "undefined"){
                //拖动速度过快，鼠标抬起时候，落点在canvas上，这时候通过e找不到group
                sg=group;
              }
              let newRect = {width:Math.round(sg.attrs.width/radio), height: Math.round(sg.attrs.height/radio), left:Math.round(sg.attrs.x/radio), top: Math.round(sg.attrs.y/radio)};
              if(newRect.width<0){
                newRect.left = newRect.left + newRect.width
                newRect.width = Math.abs(newRect.width);
              }
              if(newRect.height<0){
                newRect.top = newRect.top + newRect.height
                newRect.height = Math.abs(newRect.height);
              }
              store.commit('setTempLists',newRect)
              // console.log("vuex中添加临时展位")
            }catch(err){
              let sg = e.target.findAncestors('Group')
              console.log(sg);
              console.log(err);
            }
            //画完删除路径
            setAxisVisible(false);
            group.destroy();
            layer.batchDraw();
          }
          box=null;
        }

        if(!!line&&drawType.value==='line'){
          let groupWidth = pos.x-startPos.x;
          let groupHeight = pos.y-startPos.y;
          // console.log(Math.abs(groupWidth/radio),Math.abs(groupHeight/radio))
          if(Math.abs(groupWidth/radio)<1800 && Math.abs(groupHeight/radio)<1800){
            console.log("线段最小宽度不能小于2米");
          }else{
            try {
              let sg = e.target.findAncestors('Group')[0]
              console.log(sg);
              if(typeof sg === "undefined"){
                //拖动速度过快，鼠标抬起时候，落点在canvas上，这时候通过e找不到group
                sg=group;
              }
              let width = Math.round(sg.attrs.width/radio);
              let height = Math.round(sg.attrs.height/radio);
              let top = Math.round(sg.attrs.y/radio);
              let left = Math.round(sg.attrs.x/radio)
              let lineTop = "";
              let lineLeft = "";
              let lineSize = "";
              let lineDirection = "";
              // console.log("width:",width," height:",height, " top:",top, " left:",left);
              if(Math.abs(sg.attrs.width/radio)>Math.abs(sg.attrs.height/radio)){
                console.log("横向标尺")
                lineSize = Math.abs(width);
                lineDirection = 'hor';
                lineTop = top;
                lineLeft = left;
                if(width<0){
                  lineLeft = left+width
                }
              }else{
                console.log("纵向标尺")
                lineSize = Math.abs(height);
                lineDirection = 'ver';
                lineLeft = left;
                lineTop = top;
                if(height<0){
                  lineTop = top+height;
                }
              }
              
              //吸附边框功能
              if(lineDirection==='ver'){
                if(lineTop<guidWidth.value){
                  lineSize = lineSize + lineTop;
                  lineTop = 0;
                }
                if((selectCategory.value.height-(lineTop+lineSize))<guidWidth.value){
                  lineSize = selectCategory.value.height-lineTop;
                }
              }
              if(lineDirection==='hor'){
                if(lineLeft<guidWidth.value){
                  lineSize = lineSize + lineLeft;
                  lineLeft = 0;
                }
                if((selectCategory.value.width-(lineLeft+lineSize))<guidWidth.value){
                  lineSize = selectCategory.value.width-lineLeft;
                }
              }

              createRuler({
                token:localStorage.getItem('mtoken'),
                category_id:selectCategory.value.id,
                top:lineTop,
                left:lineLeft,
                width:lineSize,
                direction:lineDirection
              }).then((res)=>{
                console.log(res)
                store.dispatch("getLineLists",selectCategory.value.id).then(()=>{
                  drawCanvas.refreshLine();
                });
                
              })
            }catch(err){
              console.log(err);
            }
          }
          group.destroy();
          layer.batchDraw();
          line=null;
        }

        isEnd = true;
        setTimeout(()=>{isEnd = false},50);
    });

    // and core function - drawing
    stage.on('mousemove touchmove', function(e) {
        radio = width/selectCategory.value.width;
        const pos = stage.getPointerPosition();
        const {newX, newY, xIndex, yIndex} = getMouseIndex(pos,radio);
        
        if (!isPaint) {
          //鼠标没有按下，并且鼠标没有进入展位框的时候
          if(e.target===stage||(e.target.attrs&&e.target.attrs.name==='axis')){
            //绘制鼠标当前光标位置
            if(axisX&&axisY){
              if(!axisX.attrs.visible&&!isEnd){
                setAxisVisible(true);
              }
              getRectBearby(newX, newY, radio, layer);
              width = localStorage.getItem('canvasWidth')*1;
              height = localStorage.getItem('canvasHeight')*1;
              drawType.value==='rect'&&cursor.setAttrs({x: newX, y: newY, width:guidWidth.value*radio, height:guidWidth.value*radio});
              axisX.setAttrs({points:[0,newY,width,newY]});
              axisY.setAttrs({points:[newX,0,newX,width]});
              axisXText.setAttrs({x:newX+2, y: 2, width:100,text:xIndex*guidWidth.value/1000+'m'});
              axisYText.setAttrs({x:14, y:newY+2, width:100,text:yIndex*guidWidth.value/1000+'m'});
              layer.batchDraw();
            }else{
              console.log("辅助线初始化。。。。。")
              if(drawType.value==='rect'){
                cursor = new Konva.Rect({
                  name:'cursor',
                  x: newX, y: newY,
                  width: guidWidth.value*radio, height: guidWidth.value*radio,
                  fill:'red',opacity:.1
                });
              }
              //绘制x轴坐标线 x轴坐标文字
              axisX = new Konva.Line({
                points:[0,newY,width,newY],
                stroke:"red",
                opacity:.8,
                strokeWidth:0.5,
                dash: [8, 2]
              })
              //绘制y轴坐标线 y轴坐标文字
              axisY = new Konva.Line({
                points:[newX,0,newX,width],
                stroke:"red",
                opacity:.8,
                strokeWidth:0.5,
                dash: [8, 2]
              })
              //绘制x轴坐标文字
              axisXText = new Text({
                name:'axisXText',
                // x:0, y: newY-14, width:newX,
                x:newX+2, y: 2, width:100,
                text:xIndex*guidWidth.value/1000+'m',
                fill:'red',opacity:0.5,fontSize:12,align:'left'
              })
              //绘制y轴坐标文字
              axisYText = new Text({
                name:'axisYText',
                // x:newX-2, y:0, width:newY,
                x:14, y:newY+2, width:100,
                text:yIndex*guidWidth.value/1000+'m',
                fill:'red', opacity:0.5, fontSize:12, align:'left', rotation:90
              })
              layer.add(axisX);
              layer.add(axisXText);
              layer.add(axisY);
              layer.add(axisYText);
              drawType.value==='rect'&&layer.add(cursor);
              layer.draw();
            }
           
          }else{
            if(e.target.name()!=='cursor'){
              setAxisVisible(false);
              layer.batchDraw();
            }
          }
          return false;
        }else{
          //鼠标按下
          //实时对齐网格线
          // const {newX, newY} = getMouseIndex(pos,radio);
          pos.x = newX+guidWidth.value*radio;
          pos.y = newY+guidWidth.value*radio;
          
          //画矩形展位
          if(drawType.value==='rect'){
            if(!!box){
              //设置组内元素属性
              setTempRect(pos);
            }else{
              group = new Group({
                x: startPos.x, y: startPos.y,
                width: pos.x-startPos.x,
                height: pos.y-startPos.y,
                draggable: true,
                stroke: 'blue',
                strokeWidth: 1,
                name:'tempGroup'
              });
              //画矩形框
              box = new Konva.Rect({
                  name:'box',
                  x: 0, y: 0,
                  width: 0, height: 0,
                  stroke: '#000',
                  // fill:'#1890ff11',
                  fill:'red',
                  strokeWidth: 0.5,
                  opacity:0.1,
                  dash: [6, 2]
              });
              //横向尺寸
              xSize = new Text({
                name:'xSize',
                x:0, y:4, width:0,
                text:"",
                fill:'#666',fontSize:12,
                align:'center', verticalAlign:'top'
              })
              //纵向尺寸
              ySize = new Text({
                name:'ySize',
                x:16, y:0, width:0,
                text:"123",
                fill:'#666',fontSize:12,
                align:'center', verticalAlign:'top',
                rotation:90
              })
              //面积
              areaText = new Text({
                name:'areaText',
                x:0, y:4, width:0,
                text:"",
                fill:'#666',fontSize:12,
                align:'right', verticalAlign:'top'
              })
              //绘制结束坐标轴线
              if(!axisX2){
                axisX2 = new Konva.Line({
                  points:[0,0],
                  stroke:"green",
                  opacity:1,
                  strokeWidth:0.5,
                  dash: [8, 2]
                })
                axisY2 = new Konva.Line({
                  points:[0,0],
                  stroke:"green",
                  opacity:1,
                  strokeWidth:0.5,
                  dash: [8, 2]
                })
              }

              group.add(box);
              group.add(xSize);
              group.add(ySize);
              group.add(areaText);
              layer.add(axisX2);
              layer.add(axisY2);
              layer.add(group);

              store.commit("setSelectGroup",group);

              // 拖放对齐到网格
              group.on('dragend', function(e) {
                useSetDragAlign(e.target, radio);
              });
            }

            layer.batchDraw();
          }

          //画线段标尺
          if(drawType.value==='line'){
            if(!!line){
              //设置组内元素属性
              setTempLine(pos);
            }else{
              group = new Group({
                x: startPos.x, y: startPos.y,
                width: pos.x-startPos.x,
                height: pos.y-startPos.y,
                // draggable: true,
                stroke: 'blue',
                strokeWidth: 1,
                name:'tempGroup'
              });
              line = new Konva.Line({
                x: 0, y: 0,
                opacity:1,
                points: [startPos.x, startPos.y],
                stroke: "#111",
                strokeWidth: 1
              });
              //横向尺寸
              xSize = new Text({
                name:'xSize',
                x:0, y:4, width:0,
                text:"",
                fill:'#666',fontSize:12,
                align:'center', verticalAlign:'top'
              })
              //纵向尺寸
              ySize = new Text({
                name:'ySize',
                x:16, y:0, width:0,
                text:"123",
                fill:'#666',fontSize:12,
                align:'center', verticalAlign:'top',
                rotation:90
              })

              group.add(line);
              group.add(xSize);
              group.add(ySize);
              layer.add(group);
            }
            layer.batchDraw();
          }

        }
    });

    

      // 鼠标拖动时，实时设置元素位置
    const setTempRect = (pos)=>{
        let groupWidth = pos.x-startPos.x;
        let groupHeight = pos.y-startPos.y;
        startPos.x+pos.x-startPos.x
        group.setAttrs({width: groupWidth, height: groupHeight });
        box.setAttrs({width: groupWidth, height: groupHeight });

        //设置结束位置辅助线
        axisX2.setAttrs({points:[0,pos.y,width,pos.y]});
        axisY2.setAttrs({points:[pos.x,0,pos.x,width]});
        
        //画尺寸
        if(Math.abs(groupWidth/radio)>5000){
          xSize.setAttrs({
            width: Math.abs(groupWidth),
            offsetX:0,
            text:(Math.abs(groupWidth/radio/1000)).toFixed(1)+' m'
          });
          //反向拖动
          if(groupWidth<0){
            xSize.setAttrs({offsetX:-groupWidth})
          }
        }else{
          xSize.setAttrs({text:''})
        }
        if(Math.abs(groupHeight/radio)>5000){
          ySize.setAttrs({
            width: Math.abs(groupHeight),
            offsetX:0,
            text:(Math.abs(groupHeight/radio/1000)).toFixed(1)+' m'
          });
          //反向拖动
          if(groupHeight<0){
            ySize.setAttrs({offsetX:-groupHeight})
          }
        }else{
          ySize.setAttrs({text:''})
        }
        //面积参数
        if(groupWidth/radio>4000&&groupHeight/radio>4000){
          // let area = `${Math.round(groupWidth/radio/1000)}×${Math.round(groupHeight/radio/1000)}=${Math.round(groupWidth/radio*groupHeight/radio/1000000)}`
          // let area = `${(groupWidth/radio/1000).toFixed(1)}×${(groupHeight/radio/1000).toFixed(1)}=${(groupWidth/radio*groupHeight/radio/1000000).toFixed(1)}`
          // areaText.setAttrs({width: groupWidth-2,y:groupHeight-14, text:area+'m²'})
          let area = `${(groupWidth/radio*groupHeight/radio/1000000).toFixed(1)}`
          areaText.setAttrs({width: groupWidth-2,y:groupHeight-14, text:area+'m²'})
        }else{
          areaText.setAttrs({text:''})
        }
    }

    const setTempLine = (pos)=>{
      let groupWidth = pos.x-startPos.x;
      let groupHeight = pos.y-startPos.y;
      startPos.x+pos.x-startPos.x

      // console.log("groupHeight:",groupHeight)
      group.setAttrs({width: groupWidth, height: groupHeight });
      // line.setAttrs({points:[startPos.x, startPos.y, startPos.x+groupWidth, startPos.y]})
      if(Math.abs(groupWidth)>Math.abs(groupHeight)){
        line.setAttrs({points:[0,0, groupWidth, 0]})
      }else{
        line.setAttrs({points:[0,0, 0, groupHeight]})
      }
      
      //画尺寸
      if(Math.abs(groupWidth)>Math.abs(groupHeight)&&Math.abs(groupWidth/radio)>1500){
        xSize.setAttrs({
          width: Math.abs(groupWidth),
          offsetX:0,
          text:(Math.abs(groupWidth/radio/1000)).toFixed(1)
        });
        //反向拖动
        if(groupWidth<0){
          xSize.setAttrs({offsetX:-groupWidth})
        }
      }else{
        xSize.setAttrs({text:''})
      }
      if(Math.abs(groupWidth)<=Math.abs(groupHeight)&&Math.abs(groupHeight/radio)>1500){
        ySize.setAttrs({
          width: Math.abs(groupHeight),
          offsetX:0,
          text:(Math.abs(groupHeight/radio/1000)).toFixed(1)
        });
        //反向拖动
        if(groupHeight<0){
          ySize.setAttrs({offsetX:-groupHeight})
        }
      }else{
        ySize.setAttrs({text:''})
      }
    }

    //画辅助线
    const drawAxis = function(){

      
    }
    const setAxisVisible = function(bool){
      axisX && axisX.visible(bool);
      axisXText && axisXText.visible(bool);
      axisY && axisY.visible(bool);
      axisYText && axisYText.visible(bool);
      cursor && cursor.visible(bool);
      axisX2 && axisX2.remove();
      axisY2 && axisY2.remove();
      xRectAxis1&&xRectAxis1.visible(bool);
      xRectSide1&&xRectSide1.visible(bool);
      xRectText1&&xRectText1.visible(bool);
      xRectAxis2&&xRectAxis2.visible(bool);
      xRectSide2&&xRectSide2.visible(bool);
      xRectText2&&xRectText2.visible(bool);
      yRectAxis1&&yRectAxis1.visible(bool);
      yRectSide1&&yRectSide1.visible(bool);
      yRectText1&&yRectText1.visible(bool);
      yRectAxis2&&yRectAxis2.visible(bool);
      yRectSide2&&yRectSide2.visible(bool);
      yRectText2&&yRectText2.visible(bool);
      layer.batchDraw();
    }
    //键盘事件
    // usekeyBoard(stage,layer,radio);
    return layer
}



// 判断鼠标的点是否在图形内部
const isPointInClosedRegion = (mouse, rect) => {
    const { x, y } = mouse;
    const { leftTop, width, height } = rect
    const { x: minX, y: minY } = leftTop;
    const maxX = minX + width;
    const maxY = minY + height;
    if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
      return true
    }
    return false
}

const getMouseIndex = (startPos,radio) => {
  const selectCategory = computed(() => store.state.selectCategory);
  // const radio = width/selectCategory.value.width;
  // const guidWidth = store.state.guidWidth;
  const guidWidth =  computed(() => store.state.guidWidth);

  const {x,y} = startPos;
  const left_offset_pix = selectCategory.value.left_padding%guidWidth.value*radio;
  const top_offset_pix = selectCategory.value.top_padding%guidWidth.value*radio;

  let xIndex, yIndex;
  
  xIndex =Math.floor((x - left_offset_pix) / (guidWidth.value*radio));
  yIndex =Math.floor((y - top_offset_pix) / (guidWidth.value*radio));
  // xIndex =Math.round((x - left_offset_pix) / (guidWidth.value*radio));
  // yIndex =Math.round((y - top_offset_pix) / (guidWidth.value*radio));
  
  startPos.x = left_offset_pix + xIndex*guidWidth.value*radio;
  startPos.y = top_offset_pix + yIndex*guidWidth.value*radio;
  

  //返回鼠标当前选择的网格，和网格对应的坐标      
  return {
    xIndex,
    yIndex,
    newX:(left_offset_pix + xIndex*guidWidth.value*radio)*1, 
    newY:(top_offset_pix + yIndex*guidWidth.value*radio)*1
  };
}


//鼠标距离附近矩形四个方向的距离辅助线
let xRectAxis1,xRectAxis2,yRectAxis1,yRectAxis2;
let xRectText1,xRectText2,yRectText1,yRectText2;
let xRectSide1,xRectSide2,yRectSide1,yRectSide2;

//获取鼠标中心附近的矩形
const getRectBearby = (newX, newY, radio,layer) => {
  // const {newX, newY, xIndex, yIndex} = getMouseIndex(pos,radio);
  const guidWidth =  computed(() => store.state.guidWidth);
  const lists = computed(() => store.state.lists);
  const templists = computed(() => store.state.tempLists);
  const maplist = lists.value.concat(templists.value)
  //鼠标下方、上方、右侧、左侧
  let xRect1,xRect2,yRect1,yRect2; 

  for(let rect of maplist){
  // maplist.map((rect)=>{
    // console.log(rect);
    if(newX>rect.left*radio&&newX<rect.left*radio+rect.width*radio&&newY>rect.top*radio&&newY<rect.top*radio+rect.height*radio){
      // console.log("鼠标在矩形内部")
      return false;
    }
    //判断横向是否有交叉
    if(newX>=rect.left*radio&&newX<=rect.left*radio+rect.width*radio){
      // console.log("横向有交叉")
      if(newY<rect.top*radio){
        // console.log("鼠标下方")
        if(!xRect1||rect.top<xRect1.top){
          xRect1 = rect;
        }
      }else{
        // console.log("鼠标上方")
        if(!xRect2||rect.top+rect.height>=xRect2.top+xRect2.height){
          xRect2 = rect;
        }
      }
    }
    // 判断纵向是否有交叉
    if(newY>=rect.top*radio&&newY<=rect.top*radio+rect.height*radio){
      // console.log("纵向有交叉")
      if(newX<rect.left*radio){
        // console.log("鼠标右侧")
        if(!yRect1||rect.left<yRect1.left){
          yRect1 = rect;
        }

      }else{
        // console.log("鼠标左侧")
        if(!yRect2||rect.left+rect.width>yRect2.left+yRect2.width){
          yRect2 = rect;
        }
      }
    }
  // });
  }

  // console.log(xRect1,xRect2,yRect1,yRect2);
  // console.log(newY-xRect2.top*radio,guidWidth.value*radio)
  // console.log((newY-xRect2.top*radio)/(guidWidth.value*radio))

  if(xRect1){
    if(!xRectAxis1){
      xRectAxis1 = new Konva.Line({
        name:'axis',
        points:[newX,newY,newX,xRect1.top*radio],
        stroke:"red",
        opacity:1,
        strokeWidth:.5,
        listening:false
      });
      xRectSide1 = new Konva.Line({
        name:'axis',
        points:[xRect1.left*radio,xRect1.top*radio,xRect1.left*radio+xRect1.width*radio,xRect1.top*radio],
        stroke:"red",
        opacity:1,
        strokeWidth:.5,
        listening:false,
        shadowColor: 'red',
        shadowBlur: 3,
        shadowOffset: { x: 2, y: -1 },
        shadowOpacity: 0.5
      });
      xRectText1 = new Text({
        name:'axis',
        x:newX+14, y:newY, width:xRect1.top*radio-newY,
        text:Math.round((xRect1.top*radio-newY)/(guidWidth.value*radio))*(guidWidth.value/1000),
        fill:'red', opacity:0.5, fontSize:12, align:'center', rotation:90
      })
      layer.add(xRectAxis1);
      layer.add(xRectText1);
      layer.add(xRectSide1);
    }else{
      xRectAxis1.setAttrs({visible:true,points:[newX,newY,newX,xRect1.top*radio]});
      xRectSide1.setAttrs({visible:true,points:[xRect1.left*radio,xRect1.top*radio,xRect1.left*radio+xRect1.width*radio,xRect1.top*radio]});
      xRectText1.setAttrs({visible:true,x:newX+14, y:newY, width:xRect1.top*radio-newY,text:Math.round((xRect1.top*radio-newY)/(guidWidth.value*radio))*(guidWidth.value/1000)});
    }
  }else{
    xRectAxis1&&xRectAxis1.visible(false);
    xRectSide1&&xRectSide1.visible(false);
    xRectText1&&xRectText1.visible(false);
  }

  if(xRect2){
    if(!xRectAxis2){
      xRectAxis2 = new Konva.Line({
        name:'axis',
        points:[newX,xRect2.top*radio+xRect2.height*radio,newX,newY],
        stroke:"red",
        opacity:1,
        strokeWidth:.5,
        listening:false,
      })
      xRectSide2 = new Konva.Line({
        name:'axis',
        points:[xRect2.left*radio,xRect2.top*radio+xRect2.height*radio,xRect2.left*radio+xRect2.width*radio,xRect2.top*radio+xRect2.height*radio],
        stroke:"red",
        opacity:1,
        strokeWidth:.5,
        listening:false,
        shadowColor: 'red',
        shadowBlur: 3,
        shadowOffset: { x: 2, y: 1 },
        shadowOpacity: 0.5
      });
      xRectText2 = new Text({
        name:'axis',
        x:newX+14, y:xRect2.top*radio+xRect2.height*radio, width:newY-xRect2.top*radio-xRect2.height*radio,
        text:Math.round((newY-xRect2.top*radio-xRect2.height*radio)/(guidWidth.value*radio))*(guidWidth.value/1000),
        fill:'red', opacity:0.5, fontSize:12, align:'center', rotation:90
      })
      layer.add(xRectAxis2);
      layer.add(xRectSide2);
      layer.add(xRectText2);
    }else{
      xRectAxis2.setAttrs({visible:true,points:[newX,xRect2.top*radio+xRect2.height*radio,newX,newY]});
      xRectSide2.setAttrs({visible:true,points:[xRect2.left*radio,xRect2.top*radio+xRect2.height*radio,xRect2.left*radio+xRect2.width*radio,xRect2.top*radio+xRect2.height*radio]});
      xRectText2.setAttrs({visible:true,x:newX+14, y:xRect2.top*radio+xRect2.height*radio, width:newY-xRect2.top*radio-xRect2.height*radio,text:Math.round((newY-xRect2.top*radio-xRect2.height*radio)/(guidWidth.value*radio))*(guidWidth.value/1000)});
    }
  }else{
    xRectAxis2&&xRectAxis2.visible(false);
    xRectSide2&&xRectSide2.visible(false);
    xRectText2&&xRectText2.visible(false);
  }

  if(yRect1){
    if(!yRectAxis1){
      yRectAxis1 = new Konva.Line({
        name:'axis',
        points:[newX,newY+4,yRect1.left*radio,newY],
        stroke:"red",
        opacity:1,
        strokeWidth:0.5,
        listening:false,
      })
      yRectSide1 = new Konva.Line({
        name:'axis',
        points:[yRect1.left*radio,yRect1.top*radio,yRect1.left*radio,yRect1.top*radio+yRect1.height*radio],
        stroke:"red",
        opacity:1,
        strokeWidth:0.5,
        listening:false
      });
      yRectText1 = new Text({
        name:'axis',
        x:newX, y:newY, width:yRect1.left*radio-newX,
        text:Math.round((yRect1.left*radio-newX)/(guidWidth.value*radio))*(guidWidth.value/1000),
        fill:'red', opacity:0.5, fontSize:12, align:'center'
      })
      layer.add(yRectAxis1);
      layer.add(yRectSide1);
      layer.add(yRectText1);
    }else{
      yRectAxis1.setAttrs({visible:true,points:[newX,newY,yRect1.left*radio,newY]});
      yRectSide1.setAttrs({visible:true,points:[yRect1.left*radio,yRect1.top*radio,yRect1.left*radio,yRect1.top*radio+yRect1.height*radio]});
      yRectText1.setAttrs({visible:true,x:newX, y:newY+4, width:yRect1.left*radio-newX,text:Math.round((yRect1.left*radio-newX)/(guidWidth.value*radio))*(guidWidth.value/1000)});
    }
  }else{
    yRectAxis1&&yRectAxis1.visible(false);
    yRectSide1&&yRectSide1.visible(false);
    yRectText1&&yRectText1.visible(false);
  }

  if(yRect2){
    if(!yRectAxis2){
      yRectAxis2 = new Konva.Line({
        name:'axis',
        points:[yRect2.left*radio+yRect2.width*radio,newY,newX,newY],
        stroke:"red",
        opacity:1,
        strokeWidth:0.5,
        listening:false,
      })
      yRectSide2 = new Konva.Line({
        name:'axis',
        points:[yRect2.left*radio+yRect2.width*radio,yRect2.top*radio,yRect2.left*radio+yRect2.width*radio,yRect2.top*radio+yRect2.height*radio],
        stroke:"red",
        opacity:1,
        strokeWidth:0.5,
        listening:false,
        // shadowColor: 'red',
        // shadowBlur: 2,
        // shadowOffset: { x: 1, y: 1 },
        // shadowOpacity: 0.5
      });
      yRectText2 = new Text({
        name:'axis',
        x:yRect2.left*radio+yRect2.width*radio, y:newY+4, width:newX-yRect2.left*radio-yRect2.width*radio,
        text:Math.round((newX-yRect2.left*radio-yRect2.width*radio)/(guidWidth.value*radio))*(guidWidth.value/1000),
        fill:'red', opacity:0.5, fontSize:12, align:'center'
      })
      layer.add(yRectAxis2);
      layer.add(yRectSide2);
      layer.add(yRectText2);
    }else{
      yRectAxis2.setAttrs({visible:true,points:[yRect2.left*radio+yRect2.width*radio,newY,newX,newY]});
      yRectSide2.setAttrs({visible:true,points:[yRect2.left*radio+yRect2.width*radio,yRect2.top*radio,yRect2.left*radio+yRect2.width*radio,yRect2.top*radio+yRect2.height*radio]});
      yRectText2.setAttrs({visible:true,x:yRect2.left*radio+yRect2.width*radio, y:newY+4, width:newX-yRect2.left*radio-yRect2.width*radio,text:Math.round((newX-yRect2.left*radio-yRect2.width*radio)/(guidWidth.value*radio))*(guidWidth.value/1000)});
    }
  }else{
    yRectAxis2&&yRectAxis2.visible(false);
    yRectSide2&&yRectSide2.visible(false);
    yRectText2&&yRectText2.visible(false);
  }

  layer.batchDraw();

}
 
