<template>
	<header>
         <div class="left">
            <img src="@/assets/logo.png" alt="logo" />
            <!-- <h2>佛山潭洲陶瓷展</h2> -->
            <span>展位销售管理系统2025</span>
        </div>

        <div class="handle">
         <a-form laba-width="70px">
            <a-form-item label="展馆选择">
               <a-select style="width: 220px; font-size:12px" v-model:value="name" placeholder="请选择" @change="change">
                     <a-select-option
                  v-for="item in options"
                  :key="item.name"
                  :value="item.id">
                {{item.name}}
              </a-select-option>
               </a-select>
            </a-form-item>
			</a-form>

         <CopyOutlined class="copyzg" title="复制展馆" @click="copyHall()" />

         <DeleteOutlined v-if="selectIndex>9" class="delzg" title="删除展馆" @click="delHall()" />
         

         <span class="name">{{rolename}}</span>
         <span class="exit" @click="exitLogin()">退出登录</span>
        </div>
        <div class="prev" @click="prev()"></div>
        <div class="next" @click="next()"></div>
    </header>
    <a-modal
      title="复制当前展馆"
      class="copy-modal"
      style="top: 20px"
      v-model:visible="modal1Visible"
      @ok="updateCopyHall()"
    >
      
    <div class="flexbox">
      <span>展馆名称</span>
      <input type="text" placeholder="请输入展馆名称！" v-model="copyname" />
    </div>
    
    </a-modal>
</template>

<script>
// import axios from 'axios';
import { ExclamationCircleOutlined ,CopyOutlined,DeleteOutlined} from '@ant-design/icons-vue';
import { createVNode, defineComponent } from 'vue';
import {getCategory,copyCategory,removeCategory} from '../api/index'
import { Modal } from 'ant-design-vue';

export default {
   components:{CopyOutlined,DeleteOutlined},
   props:{
   editVisible:{type:Boolean, default:false}
   },
   data() {
      return {
         options: [],
         name: '',
         rolename:'',
         id:'',
         index:0,
         category:[],
         modal1Visible:false,
         copyname:"",
         copyid:''
      };
   },
   computed:{
      selectIndex(){return this.$store.state.selectIndex*1}
   },
   created(){
      let token = localStorage.getItem('mtoken');
      // let category =  JSON.parse(localStorage.getItem('category'));
      let selectCategory =  JSON.parse(localStorage.getItem('selectCategory'));

      let user = JSON.parse(localStorage.getItem('user'));

      this.rolename = user.name+'('+user.role_name+')';

      let lsCategory = localStorage.getItem("category")
      if(lsCategory === "undefined"){
         localStorage.removeItem('mtoken');
         localStorage.removeItem('user');
         localStorage.removeItem('category');
         localStorage.removeItem('selectCategory');
         this.$router.push({ path: '/login' });
         location.reload();
      }else{
         this.category=JSON.parse(lsCategory);
      }
      // this.category=JSON.parse(localStorage.getItem('category'));

      if(selectCategory!==null){
         this.name = selectCategory.name;
         this.id = selectCategory.id;
         this.$store.commit("setSelectCategory",selectCategory);
         this.copyname = selectCategory.name+"_copy";
         this.copyid = selectCategory.id;
      }

      getCategory({'token':token}).then(res=>{
         // console.log(res);
         this.options = res;
         //初始化选中第一项
         if(this.name===''){
            console.log("初始化选中第一项");
            this.name = res[0].name;
            this.id = res[0].id;
            console.log(this.name);
            this.$store.commit("setCategory",res);
            this.$store.commit("setSelectCategory",res[0]);
            localStorage.setItem('selectCategory',JSON.stringify(res[0]));
         }
         localStorage.setItem('category',JSON.stringify(res));
         //加载展位数据
         this.$store.dispatch("getLists",this.id);
         this.$store.dispatch("getLineLists",this.id);
      })

      document.addEventListener("keydown",(event)=>{
         if(event.key==="ArrowLeft"||event.key==="ArrowUp"){
            !this.editVisible && this.prev();
         }
         if(event.key==="ArrowRight"||event.key==="ArrowDown"){
            !this.editVisible && this.next();
         }
      })
   },
   methods: {
      exitLogin(){
            Modal.confirm({
               title: '退出登录?',
               icon: createVNode(ExclamationCircleOutlined),
               content: "确认退出登录？",
               okText: '退出',
               cancelText:"取消",
               onOk:() => {
                  localStorage.removeItem('mtoken');
                  localStorage.removeItem('user');
                  localStorage.removeItem('category');
                  localStorage.removeItem('selectCategory');
                  this.$router.push({ path: '/login' });
                  location.reload();
               }
            });
      },
      copyHall(){
         this.modal1Visible = true;
      },
      delHall(){
         Modal.confirm({
               title: '删除当前展馆！',
               icon: createVNode(ExclamationCircleOutlined),
               content: "删除操作不可逆，请确认！",
               okText: '确定删除',
               cancelText:"取消",
               onOk:() => {
                  removeCategory({token:localStorage.getItem('mtoken'),category_id:this.copyid}).then(res=>{
                     console.log(res)
                     if(res.errcode===200){
                        this.prev()
                        setTimeout(()=>{
                           window.location.reload();
                        },500)
                        
                     }else{
                        alert(res.errmsg);
                     }
                  })
               }
         });
      },
      updateCopyHall(){
         console.log(this.copyname,this.copyid)
         this.modal1Visible = false;
         copyCategory({token:localStorage.getItem('mtoken'),category_id:this.copyid, category_name:this.copyname}).then(res=>{
            console.log(res)
            if(res.errcode===200){
               window.location.reload();
            }else{
               alert(res.errmsg);
            }

         })
      },
      change(id){
         console.log(id);
         let categoryData = this.options[this.getValue(id).idx];
         console.log(categoryData);
         console.log(categoryData.bottom_padding);

         // if(categoryData.bottom_padding===0&&categoryData.top_padding===0&&categoryData.left_padding===0&&categoryData.right_padding===0){
         //   console.log("内边距为0");
         //   categoryData.bottom_padding = 3000;
         //   categoryData.top_padding = 3000;
         //   categoryData.left_padding = 3000;
         //   categoryData.right_padding = 3000;
         //   categoryData.width = categoryData.width+6000
         //   categoryData.height = categoryData.height+6000
         //   categoryData.isNoPadding = true;
         // }

         localStorage.setItem('selectCategory',JSON.stringify(categoryData));
         localStorage.setItem('categoryIndex',this.getValue(id).idx);
         this.$store.commit("setSelectCategory",categoryData);
         this.$store.commit("setSelectIndex",this.getValue(id).idx);
         //切换展馆时候，清空所有临时展位
         this.$store.commit("setTempListsRemoveAll",[]);
         this.$emit('change',id);

         localStorage.setItem('canvasWidth','0');
         localStorage.setItem('canvasHeight','0');

         this.copyname = categoryData.name+"_copy";
         this.copyid = categoryData.id;

         // window.location.reload();
      },
      next(){
         this.category=JSON.parse(localStorage.getItem('category'));
         let selectId = (localStorage.getItem('categoryIndex')||0)*1;
         let nextIdx = selectId+1
         if(nextIdx>this.category.length-1){
            nextIdx = 0
         }
         this.change(this.category[nextIdx].id);
         this.name = this.category[nextIdx].name;

      },
      prev(){
         this.category=JSON.parse(localStorage.getItem('category'));
         let selectId = (localStorage.getItem('categoryIndex')||0)*1;
         let nextIdx = selectId-1
         console.log(nextIdx)
         if(nextIdx===-1){
            // nextIdx = 11
            nextIdx = this.category.length-1
         }
         this.change(this.category[nextIdx].id);
         this.name = this.category[nextIdx].name;

      },
      getValue(id){
         // console.log(id);
         let name = '';
         let idx = '';
         this.options.forEach((res,index)=>{
            if(res.id == id){
            name = res.name;
            idx = index;
            }
         })
         return {name,idx};
      }
   }
}
</script>

<style scoped>
header{display: flex; align-items: center; justify-content: space-between; height:60px;  border-bottom:1px solid #eee; box-shadow: 1px 1px 5px #ccc; position:relative; padding:0 10px; position: relative;}
header .left{display:flex; align-items: flex-end;}
header .left>h2{ font-size:20px; color:#666; font-weight: bold;}
header .left>span{font-size:16px; color:#666; font-weight: bold; position:relative; left:8px;}
header .left img{height: 40px; }
header .handle{display: flex; align-items: center;}
header .handle span{margin-left:10px; color:#999;}
header .handle span.exit{cursor: pointer;}
header .handle span.exit:hover{color:#247fce;}
header .handle span.name{color:#247fce;}

header .ant-form-horizontal{margin-right:10px; width:290px; font-size:13px;}
header .ant-form-item{margin:0;}
header .handle span.copyzg{color:#247fce; cursor: pointer; font-size:15px; margin-right:2px; margin-left:8px;}
header .handle span.delzg{color:#d10b2f; cursor: pointer; font-size:17px; margin-right:2px; margin-left:8px;}

.next{width:50px; height:50px; border-top:2px solid #d10b2f; border-right:2px solid #d10b2f; position:fixed; z-index:7; right:50px; top:50%; margin-top:0px; transform: rotate(45deg); opacity:.5; cursor:pointer; transition-duration: 300ms;}
.next:hover{opacity:1;}
.prev{width:50px; height:50px; border-top:2px solid #d10b2f; border-left:2px solid #d10b2f; position:fixed; z-index:7; left:50px; top:50%; margin-top:0px; transform: rotate(-45deg); opacity:.5; cursor:pointer; transition-duration: 300ms;}
.prev:hover{opacity:1;}

.copy-modal .flexbox{display: flex; justify-content: center;}
.copy-modal .flexbox span{margin-right:5px;line-height:30px; color:#333;}
.copy-modal .flexbox input{width:320px; height:30px; line-height:30px; border:1px solid #ccc; padding:0 10px;}

</style>